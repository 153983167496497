<template lang="pug">
div(v-if='isIntegrationEnabled("yotpo")' :class='{ relative: $storyblok.isEditing.value }')
  YotpoTestimonials

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
</script>
